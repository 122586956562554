import Cookies from "js-cookie";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {routes, useGetDataList, useSearch} from "../routes/api";
import React, {useContext, useEffect, useRef, useState} from "react";
import {LoadingComponent, NoDataComponent} from "../util/loading";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {Grid, MenuItem, Select} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import {fileModel, PAGE_SIZE, updateData} from "../util/util";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SearchBox from "../components/searchBox";
import {EnumContext} from "../components/enumContext";

export default function FileList({alias, fileHeaders}) {
    const token = Cookies.get('token');
    const canCreate = Cookies.get('can_create') === 'true';
    const route = routes[alias];
    const model = fileModel[alias];
    const {enums} = useContext(EnumContext);
    const lastAlias = useRef(alias);

    const [noData, setNoData] = useState(false);
    const viewHeaders = fileHeaders.filter(key => key !== 'id').map((key) =>
        ({
            field: key,
            headerName: model.fields.fields[key].display,
            minWidth: 100,
            flex: 1,
            hide: key === 'id',
            hideable: !['app_name', 'am_name', 'f_name'].includes(key)
        }));

    const {data, loading, statusCode: status, handleCall} = useGetDataList(token, route);
    const {
        data: searchData,
        loading: searchLoading,
        statusCode: searchStatus,
        handleCall: searchCall
    } = useSearch(token, route);

    const [viewData, setViewData] = useState(null);
    const [totalPages, setTotalPages] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    const currPage = location.state?.page ? location.state.page : null;
    const currSearchParams = location.state?.searchParams ? location.state.searchParams : {};
    const currSearching = location.state?.searching ? location.state.searching : false;
    const currOpenSearch = location.state?.openSearch ? location.state.openSearch : null;
    const currColumns = location.state?.columns ? JSON.parse(location.state.columns) : null;

    const histPage = window.history.state?.page ? window.history.state.page : null;
    const histSearchParams = window.history.state?.searchParams ? window.history.state.searchParams : {};
    const histSearching = window.history.state?.searching ? window.history.state.searching : false;
    const histOpenSearch = window.history.state?.openSearch ? window.history.state.openSearch : null;
    const histColumns = window.history.state?.columns ? JSON.parse(window.history.state.columns) : null;

    const [paginationModel, setPaginationModel] = useState({
        page: histPage || currPage || 0,
        pageSize: PAGE_SIZE,
    });

    const [searchParams, setSearchParams] = useState(Object.keys(histSearchParams).length === 0 ? currSearchParams : histSearchParams);
    const [searching, setSearching] = useState(histSearching || currSearching || false);
    const [openSearch, setOpenSearch] = useState(histOpenSearch || currOpenSearch || false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(histColumns || currColumns || {
        f_feature: false,
        f_content: false,
        f_timeAdded: false,
        app_feature: false,
        app_content: false,
        app_timeAdded: false,
        am_feature: false,
        am_content: false,
        am_timeAdded: false,
    });
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        try {
            if (lastAlias.current !== null && lastAlias.current !== alias) {
                setSearchParams({});
                setSearching(false);
                setOpenSearch(false);
                lastAlias.current = alias;
                setPaginationModel((pm) => ({...pm, page: 0}));
                await handleCall({params: {page: 1}});
            } else {
                if (searching) {
                    await searchCall({params: {...searchParams, page: paginationModel.page + 1}});
                } else {
                    await handleCall({params: {page: paginationModel.page + 1}});
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [paginationModel, alias]);

    useEffect(() => {
        if (status === 204 || data?.count === 0 || data?.results?.length === 0) {
            setNoData(true);
        } else if (data?.results?.length > 0) {
            setViewData(data.results.map(data => updateData(data, null, model.prefix, enums)));
            setTotalPages(Math.ceil(data.count / PAGE_SIZE));
            setNoData(false);
        } else {
            setNoData(false);
        }
    }, [data, fileHeaders, status]);

    useEffect(() => {
        if (searchData.results?.length > 0) {
            setFilteredData(searchData.results.map(data => updateData(data, null, model.prefix, enums)));
            setTotalPages(Math.ceil(searchData.count / PAGE_SIZE));
        } else {
            setTotalPages(0);
        }
    }, [searchData, searchStatus]);


    const handleRowClick = (param, event) => {
        event.preventDefault();
        window.history.replaceState({
            from: location,
            page: paginationModel.page,
            searchParams: searching ? searchParams : {},
            searching: searching,
            openSearch: searching && openSearch,
            columns: JSON.stringify(columnVisibilityModel),
        }, '');
        navigate(`/${alias}/${param.row.id}`, {
            state: {
                from: location,
                page: paginationModel.page,
                searchParams: searching ? searchParams : {},
                searching: searching,
                openSearch: searching && openSearch,
                columns: JSON.stringify(columnVisibilityModel),
            }
        });
    };

    const handlePageSelect = (e) => {
        setPaginationModel((pm) => ({...pm, page: e.target.value}));
    }

    const handleEntry = (event) => {
        event.preventDefault();
        navigate(`/${alias}/new`, {
            state: {
                from: location,
                page: paginationModel.page,
                searchParams: searching ? searchParams : {},
                searching: searching,
                openSearch: searching && openSearch,
                columns: JSON.stringify(columnVisibilityModel),
            }
        });
    };

    const handleSearch = async () => {
        setFilteredData([]);
        setSearching(true);
        setPaginationModel((pm) => ({...pm, page: 0}));
    }

    const handleOpenSearch = () => {
        setOpenSearch(true);
    }

    const handleCancelSearch = () => {
        if (searching) {
            setPaginationModel((pm) => ({...pm, page: 0}));
        }
        setOpenSearch(false);
        setSearching(false);
        setFilteredData([]);
        setSearchParams({});
    }

    if ((!searching && loading) || (searching && searchLoading)) return <LoadingComponent/>;
    if (noData) return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justifyContent="space-between"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias={alias} display={model.display}/>
            </Grid>
            <Grid item xs={9}>
                <Typography variant='h4'>{model.display}s</Typography>
            </Grid>
            {canCreate && <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button component="label"
                        variant="contained"
                        onClick={handleEntry}
                        startIcon={<AddIcon/>}
                >
                    {`New ${model.display}`}
                </Button>
            </Grid>}
            <Grid item xs={12}>
                <NoDataComponent/>
            </Grid>
        </Grid>
    )

    return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justifyContent="space-between"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias={alias} display={model.display}/>
            </Grid>
            <Grid item xs={9}>
                <Typography variant='h4'>{model.display}s</Typography>
            </Grid>
            {canCreate &&
                <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button component="label"
                            variant="contained"
                            onClick={handleEntry}
                            startIcon={<AddIcon/>}
                    >
                        {`New ${model.display}`}
                    </Button>
                </Grid>}
            <Grid item xs={9}/>
            <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    component="label"
                    onClick={openSearch ? handleCancelSearch : handleOpenSearch}
                    startIcon={<ManageSearchIcon/>}
                    sx={{mb: 1}}>
                    {openSearch ? "Cancel" : "Filters"}
                </Button>
            </Grid>
            {openSearch && <SearchBox
                prefix={model.prefix}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                searchFields={model.searchFields}
                sendSearch={handleSearch}
            />}
            <Grid item xs={12}/>
            {searching && <Grid item xs={12}>
                <Typography variant='h6'>
                    Filtered Results:
                </Typography>
            </Grid>}
            <Grid item xs={12}>
                <DataGrid
                    disableColumnFilter
                    columns={viewHeaders}
                    rows={searching ? filteredData : viewData}
                    rowCount={(searching ? searchData.count : data.count) || 0}
                    paginationMode="server"
                    slots={{toolbar: GridToolbar}}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}
                    autoHeight={true}
                    rowHeight={80}
                    onRowClick={handleRowClick}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={setColumnVisibilityModel}
                />
            </Grid>
            {Boolean(totalPages) &&
                <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end", alignItems: 'center'}}>
                    <Typography sx={{mr: 1}}>Page: </Typography>
                    <Select
                        name='Page'
                        key='page'
                        onChange={handlePageSelect}
                        value={paginationModel.page}
                        sx={{minWidth: 70}}
                    >
                        {Array.from({length: totalPages}, (_, index) => (
                            <MenuItem key={index} value={index}>
                                {index + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>}
        </Grid>
    )
}