import Cookies from "js-cookie";
import {useLocation, useNavigate} from "react-router-dom";
import {routes, useGetDataById} from "../routes/api";
import React, {useContext, useEffect, useState} from "react";
import {LoadingComponent, NoDataComponent} from "../util/loading";
import {Card, CardContent, CardHeader, Grid, IconButton} from "@mui/material";
import {organizationFields} from "../util/modelFields";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import ContentDetail from "../components/contentDetail";
import {updateData} from "../util/util";
import {OrganizationHeaders} from "../util/getFields";
import {EnumContext} from "../components/enumContext";


export default function OrganizationDetail() {
    const token = Cookies.get('token');
    const route = routes.organization;
    const canCreate = Cookies.get('can_create') === 'true';
    const {enums} = useContext(EnumContext);

    const [noData, setNoData] = useState(false);
    const [newData, setNewData] = useState({});
    const [edit, setEdit] = useState(false);

    const {data, loading, statusCode, handleCall} = useGetDataById(token, route, '0');

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (statusCode === 404 || statusCode === 421) {
            setNoData(true);
        } else if (statusCode > 400) {
            navigate('/');
        }
    }, [statusCode]);

    useEffect(() => {
        if (JSON.stringify(data) !== '{}') {
            setNewData(updateData(data, OrganizationHeaders, 'o', enums));
        }
    }, [data]);

    const fetchDetail = async () => {
        try {
            await handleCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchDetail();
    }, []);


    if (noData) return <NoDataComponent message="User is not in an organization"/>
    if (loading || Object.keys(newData).length === 0) return <LoadingComponent/>;

    return <Grid
        container
        spacing={2}
        alignItems="stretch"
        justifyContent="center"
        sx={{margin: 'auto', mt: 5, width: '80%'}}
    >
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12} key="nav">
            <NavigateBreadcrumbs alias='organization' display='Organization' id={newData.o_name}  state={location.state}/>
        </Grid>
        <Grid item xs={12} sx={{mb: 3}}>
            <Typography variant='h4'>{newData.o_name}</Typography>
        </Grid>
        <Grid item xs={12} sx={{mb: 5}}/>
        <Grid item xs={12} key="org-detail" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={canCreate ?
                        <IconButton onClick={() => setEdit(!edit)}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title='Details'
                />
                <CardContent>
                    <ContentDetail
                        alias='organization'
                        id={newData.id}
                        data={newData}
                        edit={edit}
                        setEdit={setEdit}
                        refresh={fetchDetail}
                        fields={organizationFields}
                    />
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}