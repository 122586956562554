import Cookies from "js-cookie";
import {routes, useGetDataList, useSearch} from "../routes/api";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AccountListHeaders} from "../util/getFields";
import {LoadingComponent, NoDataComponent} from "../util/loading";
import {Grid, MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import SearchBox from "../components/searchBox";
import {accountSearchFields} from "../util/searchFields";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {PAGE_SIZE} from "../util/util";
import {accountFields, userFields} from "../util/modelFields";

export default function AccountList() {
    const token = Cookies.get('token');
    const canCreate = Cookies.get('can_create') === 'true';
    const route = routes.account;
    const allFields = {...accountFields.fields, ...userFields.fields};

    const [noData, setNoData] = useState(false);
    const [viewData, setViewData] = useState([]);
    const viewHeaders = AccountListHeaders.filter(key => key !== 'id').map((key) =>
        ({
            field: key,
            headerName: allFields[key].display,
            type: allFields[key].type === 'boolean' ? 'boolean' : 'string',
            minWidth: 100,
            flex: 1,
            hideable: key !== 'username',
            valueGetter: (value) => (
                typeof value === 'boolean' ?
                    (value ?
                        'Yes' :
                        'No')
                    : (Array.isArray(value) ?
                        value.reduce((acc, item) => (acc ? `${acc}, ${item}` : item), '') :
                        value)
            )
        }));

    const [totalPages, setTotalPages] = useState(null);

    const {data, loading, statusCode: status, handleCall} = useGetDataList(token, route);
    const {
        data: searchData,
        loading: searchLoading,
        statusCode: searchStatus,
        handleCall: searchCall
    } = useSearch(token, route);


    const navigate = useNavigate();
    const location = useLocation();

    const currPage = location.state?.page ? location.state.page : null;
    const currSearchParams = location.state?.searchParams ? location.state.searchParams : {};
    const currSearching = location.state?.searching ? location.state.searching : false;
    const currOpenSearch = location.state?.openSearch ? location.state.openSearch : null;
    const currColumns = location.state?.columns ? JSON.parse(location.state.columns) : null;

    const histPage = window.history.state?.page ? window.history.state.page : null;
    const histSearchParams = window.history.state?.searchParams ? window.history.state.searchParams : {};
    const histSearching = window.history.state?.searching ? window.history.state.searching : false;
    const histOpenSearch = window.history.state?.openSearch ? window.history.state.openSearch : null;
    const histColumns = window.history.state?.columns ? JSON.parse(window.history.state.columns) : null;

    const [paginationModel, setPaginationModel] = useState({
        page: histPage || currPage || 0,
        pageSize: PAGE_SIZE,
    });

    const [searchParams, setSearchParams] = useState(Object.keys(histSearchParams).length === 0 ? currSearchParams : histSearchParams);
    const [searching, setSearching] = useState(histSearching || currSearching || false);
    const [openSearch, setOpenSearch] = useState(histOpenSearch || currOpenSearch || false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(histColumns || currColumns || {
        a_phone: false,
        a_type: false,
        is_staff: false,
    });

    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        try {
            if (searching) {
                await searchCall({params: {...searchParams, page: paginationModel.page + 1}});
            } else {
                await handleCall({params: {page: paginationModel.page + 1}});
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchData();
    }, [paginationModel]);

    useEffect(() => {
        if (status === 204 || data?.count === 0 || data?.results?.length === 0) {
            setNoData(true);
        } else if (data?.results?.length > 0) {
            setViewData(data.results.map((item) => ({...item, ...item.a_user, id: item.id})));
            setNoData(false);
            setTotalPages(Math.ceil(data.count / PAGE_SIZE));
        } else {
            setNoData(false);
        }
    }, [data, status]);

    useEffect(() => {
        if (searchData.results?.length > 0) {
            setFilteredData(searchData.results.map((item) => ({...item.a_user, id: item.id})));
            setTotalPages(Math.ceil(searchData.count / PAGE_SIZE));
        } else {
            setTotalPages(0);
        }
    }, [searchData, searchStatus]);

    const handleRowClick = (param, event) => {
        event.preventDefault();
        window.history.replaceState({
            from: location,
            page: paginationModel.page,
            searchParams: searching ? searchParams : {},
            searching: searching,
            openSearch: searching && openSearch,
            columns: JSON.stringify(columnVisibilityModel),
        }, '');
        navigate(`/account/${param.row.id}`, {
            state: {
                from: location,
                page: paginationModel.page,
                searchParams: searching ? searchParams : {},
                searching: searching,
                openSearch: searching && openSearch,
                columns: JSON.stringify(columnVisibilityModel),
            }
        });
    };
    const handleEntry = (event) => {
        event.preventDefault();
        navigate(`/account/new`, {
            state: {
                from: location,
                page: paginationModel.page,
                searchParams: searching ? searchParams : {},
                searching: searching,
                openSearch: searching && openSearch,
                columns: JSON.stringify(columnVisibilityModel),
            }
        });
    };

    const handleSearch = async () => {
        setFilteredData([]);
        setSearching(true);
        setPaginationModel((pm) => ({...pm, page: 0}));
    }

    const handleOpenSearch = () => {
        setOpenSearch(true);
    }

    const handleCancelSearch = () => {
        if (searching) {
            setPaginationModel((pm) => ({...pm, page: 0}));
        }
        setOpenSearch(false);
        setSearching(false);
        setSearchParams({});
        setFilteredData([]);
    }

    const handlePageSelect = (e) => {
        setPaginationModel((pm) => ({...pm, page: e.target.value}));
    }

    if (((!searching && loading) || (searching && searchLoading))) return <LoadingComponent/>
    if (noData) return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justify="center"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='account' display='Account'/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h4'>Accounts</Typography>
            </Grid>
            {canCreate &&
                <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button component="label"
                            variant="contained"
                            onClick={handleEntry}
                            startIcon={<AddIcon/>}
                    >
                        New Account
                    </Button>
                </Grid>}
            <Grid item xs={12}>
                <NoDataComponent/>
            </Grid>
        </Grid>
    )

    return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justifyContent="center"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='account' display='Account'/>
            </Grid>
            <Grid item xs={9}>
                <Typography variant='h4'>Accounts</Typography>
            </Grid>
            {canCreate && <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    component="label"
                    variant="contained"
                    onClick={handleEntry}
                    startIcon={<AddIcon/>}
                >
                    New Account
                </Button>
            </Grid>}
            <Grid item xs={9}/>
            <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    component="label"
                    onClick={openSearch ? handleCancelSearch : handleOpenSearch}
                    startIcon={<ManageSearchIcon/>}
                    sx={{mb: 1}}>
                    {openSearch ? "Cancel" : "Search"}
                </Button>
            </Grid>
            {openSearch && <SearchBox
                prefix='a'
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                searchFields={accountSearchFields}
                sendSearch={handleSearch}
            />}
            <Grid item xs={12}/>
            {searching && <Grid item xs={12}>
                <Typography variant='h6'>
                    Filtered Results:
                </Typography>
            </Grid>}
            <Grid item xs={12}>
                <DataGrid
                    disableColumnFilter
                    disableDensitySelector
                    columns={viewHeaders}
                    rows={searching ? filteredData : viewData}
                    rowCount={(searching ? searchData.count : data.count) || 0}
                    paginationMode="server"
                    slots={{toolbar: GridToolbar}}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}
                    autoHeight={true}
                    rowHeight={80}
                    onRowClick={handleRowClick}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={setColumnVisibilityModel}

                />
            </Grid>
            {Boolean(totalPages) &&
                <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end", alignItems: 'center'}}>
                    <Typography sx={{mr: 1}}>Page: </Typography>
                    <Select
                        name='Page'
                        key='page'
                        onChange={handlePageSelect}
                        value={paginationModel.page}
                        sx={{minWidth: 70}}
                    >
                        {Array.from({length: totalPages}, (_, index) => (
                            <MenuItem key={index} value={index}>
                                {index + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>}
        </Grid>
    )
}