import Cookies from "js-cookie";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {routes, useGetDataById} from "../routes/api";
import React, {useEffect, useState} from "react";
import {LoadingComponent} from "../util/loading";
import {Card, CardContent, CardHeader, Grid, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EditIcon from "@mui/icons-material/Edit";
import ContentDetail from "../components/contentDetail";
import PopupDialog from "../components/popupDialog";
import RelationEdit from "../components/relationEdit";
import {accountFields} from "../util/modelFields";
import {DeleteComponent} from "../components/confirmationDialog";
import Tooltip from "@mui/material/Tooltip";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import ErrorDisplay from "../components/errorDisplay";
import {grey} from "@mui/material/colors";

export default function AccountDetail() {
    const token = Cookies.get('token');
    const {id} = useParams();
    const route = routes.account;
    const canCreate = Cookies.get('can_create') === 'true';

    const [edit, setEdit] = useState(false);
    const [popup, setPopup] = useState(false);
    const [deleteError, setDeleteError] = useState({error: false, message: null, status: null});

    const {data, loading, statusCode, handleCall} = useGetDataById(token, route, id);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (statusCode > 400) {
            navigate('/account');
        }
    }, [statusCode]);

    const fetchDetail = async () => {
        try {
            await handleCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchDetail();
    }, []);

    if (loading || Object.keys(data).length === 0) return <LoadingComponent/>

    return <Grid
        container
        spacing={2}
        alignItems="stretch"
        justifyContent="center"
        sx={{margin: 'auto', mt: 5, width: '80%'}}
    >
        <Grid item xs={12}>
            <ErrorDisplay
                fields={accountFields}
                alert={deleteError.error}
                setAlert={(alert) => setDeleteError({...deleteError, error: alert})}
                response={deleteError.message}
                statusCode={deleteError.status}
            />
        </Grid>
        <Grid item xs={9} key="nav">
            <NavigateBreadcrumbs alias='account' display='Account' id={data.data.a_user.username}
                                 state={location.state}/>
        </Grid>
        <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
            {/*<Button variant="outlined" startIcon={<DownloadIcon style={{fontSize: 20}}/>}>*/}
            {/*    Download*/}
            {/*</Button>*/}
        </Grid>
        <Grid item xs={12} sx={{mb: 3}}>
            <Typography variant='h4'>Account {data.data.a_user.username}</Typography>
        </Grid>
        <Grid item xs={4} sm={3} lg={2} sx={{mb: 5}}>
            {canCreate &&
                <DeleteComponent
                    alias='account'
                    id={id}
                    navigate={navigate}
                    setError={setDeleteError}
                />}
        </Grid>
        <Grid item xs={8} sm={9} lg={10}/>
        <Grid item xs={12} key="account-detail" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={canCreate ?
                        <IconButton onClick={() => setEdit(!edit)}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title='Details'
                />
                <CardContent>
                    <ContentDetail
                        alias='account'
                        id={id}
                        data={data.data}
                        edit={edit}
                        setEdit={setEdit}
                        refresh={fetchDetail}
                        fields={accountFields}
                    />

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} key="properties">
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={canCreate ?
                        <IconButton onClick={() => (setPopup(!popup))}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title="Additional Information"/>
                {data.AccountProperty.length === 0 ?
                    <CardContent>
                        <IconButton disableRipple>
                            <FormatListBulletedIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            No additional information
                        </Typography>
                    </CardContent> :
                    <CardContent>
                        <Grid container
                              spacing={1}
                              sx={{margin: 'auto', mb: 2}}
                              alignItems="center"
                              justifyContent="center"
                        >
                            {data.AccountProperty.map(([, [key, value, desc]]) => (
                                [
                                    <Grid item xs={5} key={key} sx={{mb: 1}}>
                                        <Tooltip title={desc} arrow>
                                            <Typography variant="body1" color={grey[700]}>
                                                {key}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>,
                                    <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                                        <Tooltip title={desc} arrow placement="bottom-start">
                                            <Typography variant="body1">
                                                {value}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                ]
                            ))}
                        </Grid>
                    </CardContent>}
            </Card>
        </Grid>
        <PopupDialog
            open={popup}
            content={<RelationEdit
                fields={accountFields}
                alias='account'
                display='Account'
                id={id}
                relation='AccountProperty'
                refresh={fetchDetail}
                defaultValue={data.AccountProperty.map(([id, [key, value]]) => ([id, `${key}: ${value}`]))}
                setEdit={(value) => (setPopup(value))}
            />}
            handleClose={() => setPopup(false)}
        />
    </Grid>
}